import { useCallback, useState } from "react";
import useApiClient from "./useApiClient";

export type Provider = {
  integrationId: string;
  name: string;
  description: string;
  logoUrl: string;
};

type ProvidersResponse = {
  success: boolean;
  message: string;
  responseObject: Provider[];
  statusCode: number;
};

export const useFetchProviders = () => {
  /*
   * State
   */

  const [providers, setProviders] = useState<Provider[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  /*
   * Hooks
   */

  const { get } = useApiClient();

  /*
   * Handlers
   */

  const fetchProviders = useCallback(async () => {
    setLoading(true);

    setError(null);

    try {
      const response = await get<ProvidersResponse>("/integrations");
      if (response.data) {
        setProviders(
          response.data.responseObject.map(({ integrationId, name, description, logoUrl }) => ({
            integrationId,
            name,
            description,
            logoUrl,
          })),
        );
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError("An error occurred while fetching providers");
    } finally {
      setLoading(false);
    }
  }, [get]);

  return { providers, loading, error, refetch: fetchProviders };
};
