import React, { useState } from 'react';
import styled from 'styled-components';

import { Provider } from '../../hooks/useFetchProviders';

interface ProviderSelectProps {
  providers: Provider[];
  onSelect: (provider: Provider) => void;
}

const ProviderSelect: React.FC<ProviderSelectProps> = ({
  providers,
  onSelect
}) => {
  /*
   * State
   */

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  /*
   * Handlers
   */

  const handleSelect = (provider: Provider) => {
    setSelectedProvider(provider);

    onSelect(provider);

    setIsOpen(false);
  };

  /*
   * Component
   */

  return (
    <SelectContainer>
      <SelectButton onClick={() => setIsOpen(!isOpen)}>
        {selectedProvider ? selectedProvider.name : 'Select a provider'}
      </SelectButton>
      {isOpen && (
        <DropdownList>
          {providers.map((provider) => (
            <DropdownItem
              key={provider.integrationId}
              onClick={() => handleSelect(provider)}
            >
              {provider.name}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  position: relative;
  width: 200px;
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export default ProviderSelect;
