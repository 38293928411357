import { useCallback, useState } from "react";
import useApiClient from "./useApiClient";

type SessionResponse = {
  success: boolean;
  message: string;
  responseObject: {
    uuid: string;
  };
  statusCode: number;
};

export const useCreateSession = () => {
  /*
   * State
   */

  const [sessionId, setSessionId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { post } = useApiClient();

  const createSession = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await post<SessionResponse>("/sessions");
      if (response.data) {
        setSessionId(response.data.responseObject.uuid);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError("An error occurred while creating a session");
    } finally {
      setLoading(false);
    }
  }, [post]);

  return { sessionId, loading, error, createSession };
};
