import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import ProviderSelect from '../components/Demo/ProviderSelect';
import { ThemedText } from '../theme/text';
import { colors } from '../theme/colors';
import { useFetchProviders, Provider } from '../hooks/useFetchProviders';
import { useCreateSession } from '../hooks/useCreateSession';

export const Landing: React.FC = () => {
  /*
   * State
   */

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);

  const [sessionId, setSessionId] = useState<string | null>(null);

  /*
   * Hooks
   */

  const { providers, refetch: fetchProviders } = useFetchProviders();
  const { sessionId: fetchedSessionId, createSession } = useCreateSession();

  useEffect(() => {
    const fetchProvidersAsync = async () => {
      await fetchProviders();
    };

    fetchProvidersAsync();
  }, []);

  useEffect(() => {
    const initSession = async () => {
      const storedSessionId = localStorage.getItem('sessionId');

      if (storedSessionId) {
        setSessionId(storedSessionId);
      } else {
        await createSession();
      }
    };

    initSession();
  }, []);

  useEffect(() => {
    if (fetchedSessionId) {
      setSessionId(fetchedSessionId);

      localStorage.setItem('sessionId', fetchedSessionId);
    }
  }, [fetchedSessionId]);

  /*
   * Handlers
   */

  /*
   * Helpers
   */

  const formatProviderLink = (provider: Provider | null, sessionUUID: string | null): string => {
    if (!provider || !sessionUUID) {
      return '';
    }

    const integrationData = JSON.stringify({
      integration: { id: provider.integrationId }
    });
    const encodedData = encodeURIComponent(integrationData);
    const providerLink = `https://aasa.zkp2p.xyz/requestedProofs/${encodedData}?sessionId=${sessionUUID}`;

    return providerLink;
  };

  const link = formatProviderLink(selectedProvider, sessionId);

  /*
   * Component
   */

  return (
    <PageWrapper>
      {/* <TopNav onContactClick={handleContactClick} /> */}

      <BodyContainer>
        <ThemedText.HeadlineLarge>Customer Data Verification Demo</ThemedText.HeadlineLarge>

        <ProviderSelectAndQRContainer>
          <ProviderSelectorContainer>
            <ThemedText.HeadlineSmall>Choose a Data Source to Begin</ThemedText.HeadlineSmall>
            <ProviderSelect
              providers={providers}
              onSelect={(provider: Provider) => {
                console.log(provider);

                setSelectedProvider(provider);
              }}
            />
          </ProviderSelectorContainer>

          <VerticalDivider />

          {selectedProvider && (
            <QRContainer>
              <QRCode value={link} size={192} />
            </QRContainer>
          )}
        </ProviderSelectAndQRContainer>
      </BodyContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
`;

const BodyContainer = styled.div`
  min-height: 535px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;
  padding: 3rem 1rem 1rem 1rem;
  max-width: calc(100vw - 10rem);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ProviderSelectAndQRContainer = styled.div`
  min-height: 200px;
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  gap: 2rem;
`;

const ProviderSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const VerticalDivider = styled.div`
  height: 100px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;

const QRContainer = styled.div`
  padding: 1.25rem 1.25rem 1rem 1.25rem;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;
  background: #131a2a;
`;

export default Landing;
